/* .ant-upload-span {
  display: none !important;
} */

body{
	background-color: #EDEDEE !important;
  }

.ant-pagination-item-active {
	background: #144881 !important;
}

.ant-input-focused,
.ant-input:focus {
	border-color: #00477e;
	box-shadow: rgb(62 121 247 / 20%) 0px 0px 0px 0px;
}
.ant-input:hover {
	border-color: #144881;
}
.ant-input-affix-wrapper:hover {
	border-color: #144881;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: rgb(20, 72, 129);
}
.style-btn {
	background-color: #00477e;
	color: #ddd;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):focus,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):hover {
	background-color: #1448810d !important;
	color: #144881 !important;
}
.ant-modal-header {
	background: #1448811f !important;
}
.ant-select-selection-overflow {
	display: contents !important;
}
.ant-input-disabled,
.ant-input[disabled] {
	color: rgb(69, 85, 96) !important;
}
.ant-picker-input > input[disabled] {
	color: rgb(69, 85, 96) !important;
}
.ant-spin-dot-item {
	background-color: #00477e !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #00477e !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: #00477e !important;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
	color: #00477e !important;
}
.steps-content {
	min-height: 350px;
	max-height: 350px;
	text-align: center;
	background-color: #f7f6f9;
	border: 1px dashed #e9e9e9;
	border-radius: 2px;
	overflow: auto;
}
.ant-radio-checked .ant-radio-inner {
	border-color: #00477e;
}
.ant-radio-checked::after {
	border: #00477e !important;
}
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
	border-color: #00477e !important;
}
.ant-radio-inner::after {
	background-color: #00477e !important;
}
.outer-radio-role {
	border: 5px solid transparent;
	padding: 25px;
	margin-bottom: 15px;
	box-shadow: 0 3px 3px #ddd;
	text-align: left;
	background: #fff;
}
.outer-radio-facility {
	border: 5px solid transparent;
	background: #fff;
	padding: 4px;
	margin-bottom: 15px;
	box-shadow: 0 3px 3px #ddd;
	text-align: left;
}
.outer-radio-user {
	border: 5px solid transparent;
	background: #fff;
	padding: 4px;
	margin-bottom: 15px;
	box-shadow: 0 3px 3px #ddd;
	text-align: left;
}
.outer-radio:hover {
	background: #00477e;
	color: #fff !important;
}
.outer-radio:hover span {
	color: #fff !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
	border: #00477e !important;
	background-color: #00477e !important;
}
.ant-checkbox-checked::after {
	border: #00477e !important;
}
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
	border-color: #00477e !important;
}
.ant-select-selection-item {
	color: rgb(69, 85, 96) !important;
}
.ant-tabs-tab:hover {
	color: #144881 !important;
}
.view-facility {
	min-height: 350px;
	max-height: 350px;
	text-align: left;

	border-radius: 2px;
	overflow: auto;
}
.view-title {
	font-weight: 600;
	font-size: 16px;
}
.view-content {
	font-size: 15px;
}
.bg-desc {
	background: #f0eef5;
	padding: 8px 10px;
	border-radius: 8px;
	border: solid 1px #dfdde4;
	margin-bottom: 10px;
}
.fw-500 {
	font-weight: 500;
}

.facility-steps-content {
	min-height: 500px;
	max-height: 500px;
	/* text-align: center; */
	/* background-color: #f7f6f9; */
	/* border: 1px dashed #e9e9e9; */
	border-radius: 2px;
	overflow-y: auto;
}
.scroll-over {
	min-height: 500px;
	max-height: 0px !important;
	/* text-align: center; */
	/* background-color: #f7f6f9; */
	/* border: 1px dashed #e9e9e9; */
	border-radius: 2px;
	/* overflow-y: auto; */
}
.ant-tag {
	color: #00477e !important;
}

.kpi-main-report {
	min-height: 300px;
	border-radius: 5px;
	max-height: 300px;
}

.ant-tabs-ink-bar {
	background: #144881 !important;
}

.ant-picker-focused,
.ant-picker:hover {
	border-color: #00477e !important;
}
.ant-picker-today-btn {
	color: #00477e !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	border: 1px solid rgb(20, 72, 129) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
	background: rgb(20, 72, 129) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today {
	color: #144881 !important;
}

.loader-spin {
	margin-top: 175px;
}

.ant-radio-inner {
	border-color: rgb(20, 72, 129) !important;
}

.ant-checkbox-inner {
	border-color: rgb(20, 72, 129) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: #00477e !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	color: #00477e !important;
}
