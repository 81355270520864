.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #00477e !important;
}

.ant-tabs-tab:hover {
	color: #00477e !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: #00477e !important;
}

.ant-picker-focused,
.ant-picker:hover {
	border-color: #00477e !important;
}

.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-item-active,
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-item-open,
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-item-selected,
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-submenu-active,
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-submenu-open,
.top-nav.dark .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
	background-color: #aebecf !important;
}

.ant-btn {
	color: #00477e !important;
	/* background-color: #afa9b90d !important; */
	border-color: #421d810d !important;
}

.ant-btn-round {
	height: 40px;
	padding: 8.5px 20px;
	font-size: 14px;
	border-radius: 40px;
}

.ant-table-thead > tr > th {
	background: #eff3f5 !important;
}

.ant-select-item .ant-select-item-option .ant-select-item-option-selected {
	color: #00477e !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	color: #00477e !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
	background: #00477e !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today {
	color: #00477e !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	border: 1px solid #00477e !important;
}

.ant-picker-header-view button:hover {
	color: #00477e !important;
}

.ant-pagination-item-active {
	background: #00477e !important;
}

.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
	color: #00477e !important;
	border-color: #00477e !important;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
	color: #00477e !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
	color: #00477e !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	color: #00477e !important;
}

.ant-tabs-ink-bar {
	background: #00477e !important;
}

/* .ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
    position: relative;
    background-color: rgb(255, 255, 255);
    border: 1px solid #00477E !important;
    box-shadow: none !important;
  } */
.ant-form-item-control-input:focus-visible {
	outline: -webkit-focus-ring-color #00477e 0px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
	border-top: 1px dashed #00477e !important;
	border-bottom: 1px dashed #00477e !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
	background: #ede5f8 !important;
}

.ant-spin-dot-item {
	background-color: #00477e !important;
}

.text-primary {
	color: #00477e !important;
}

.nav-item .user_profile {
	width: 220px !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
	border-radius: 10px !important;
}

.anticon-logout {
	margin: 0 10px 0 0 !important;
}

.ant-dropdown-menu-light {
	padding: 0 !important;
}
.ant-btn-primary {
	color: #ffffff !important;
}
